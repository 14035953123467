<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4 noprint">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-organization-list' }">Organizations</router-link>
                    <template v-if="organization">
                    &gt;
                    <router-link :to="{ name: 'organization-dashboard', params: { organizationId: this.$route.params.organizationId } }">{{ organizationName }}</router-link>
                    </template>
                </p>
                </v-col>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ name }}</h1>
                <p class="text-caption text-center">Organization overview</p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10 mt-2" v-if="organization">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-alert dense type="warning" v-if="!organizationSummary.brandprofile">
                        <router-link :to="{ name: 'organization-edit-brandprofile', params: { organizationId: this.$route.params.organizationId } }" class="white--text">Set up a BrandProfile</router-link> to customize your storefront.
                    </v-alert>
                    <v-alert dense type="warning" v-if="!organizationSummary.stripe_account_id">
                        <router-link :to="{ name: 'organization-edit-stripe', params: { organizationId: this.$route.params.organizationId } }" class="white--text">Set up a Stripe account</router-link> to accept payments.
                    </v-alert>
                    <v-card>
                        <v-app-bar color="green darken-2" dark flat dense>
                            <v-tooltip bottom>
                                <template #activator="{ on, attrs }">
                                    <v-btn icon @click="$router.go(-1)" v-bind="attrs" v-on="on">
                                        <font-awesome-icon :icon="['fas', 'chevron-left']" style="font-size: 20px;" fixed-width/>
                                    </v-btn>
                                </template>
                                Back
                            </v-tooltip>
                            <v-app-bar-title>Storefront settings</v-app-bar-title>
                        </v-app-bar>
                        <!-- TODO: more settings for the 'order' link and maybe others like categories, deals, downloads, books, clothing, etc. -->
                        <!-- <v-simple-table dense>
                            <template #default>
                                <tbody>
                                    <tr>
                                        <th style="min-width: 100px;">Display 'shop' link at the top</th>
                                        <td style="width: 100%;">
                                            <EditableText :value="TODO_something_goes_here" @input="saveDisplayShopLink" dense/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th style="min-width: 100px;">Display 'donate' link at the top</th>
                                        <td style="width: 100%;">
                                            <EditableText :value="TODO_something_goes_here" @input="saveDisplayDonateLink" dense/>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table> -->
                        <v-card-text>
                            <p class="text-overline mb-0 mt-2">Customer Website</p>
                            <p class="mb-0 pb-0" v-if="organizationSummary.customer_website_url">
                                Customer Website URL: <a :href="organizationSummary.customer_website_url" target="_blank">{{organizationSummary.customer_website_url }}</a>
                            </p>
                            <p class="mb-0 pb-0" v-if="!organizationSummary.customer_website_url">
                                Your customer website URL is not configured. Set up a BrandProfile to create your customer website.
                            </p>

                            <p class="text-overline mb-0 mt-8">Authentication Mode</p>
                            <p class="mb-0 pb-0">
                                You decide if Unicorn Springs, or your own website, or a 3rd party single sign-on (SSO) service should manage authentication.
                            </p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'organization-edit-authentication-mode', params: { organizationId: this.$route.params.organizationId } }">Manage authentication mode</router-link>
                            </p>

                            <p class="text-overline mb-0 mt-8">Registration Mode</p>
                            <p class="mb-0 pb-0">
                                You decide if Unicorn Springs, or your own website, should manage new account creation.
                            </p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'organization-edit-registration-mode', params: { organizationId: this.$route.params.organizationId } }">Manage registration mode</router-link>
                            </p>

                            <p class="text-overline mb-0 mt-8">BrandProfile</p>
                            <p class="mb-0 pb-0">
                                You can link your organization with a BrandProfile to show your logo and custom brand color palette on your customer service website.
                            </p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'organization-edit-brandprofile', params: { organizationId: this.$route.params.organizationId } }">Manage BrandProfile</router-link>
                            </p>

                            <!-- NOTE: Custom Hostname section is limited to service admin only for now; when we have webauthz flow implemented with brandprofile.org then it will be available for customers as self-service if it was already verified bry brandprofile.org; otherwise maybe webatuzh with lbiertydns.org or libertycdn.io -->
                            <template v-if="isPermitServiceAdmin">
                            <p class="text-overline mb-0 mt-8">Custom Hostname (Service Administrator ONLY) <font-awesome-icon :icon="['fas', 'id-badge']" class="green--text text--darken-2"></font-awesome-icon></p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'organization-edit-hostname', params: { organizationId: this.$route.params.organizationId } }">Custom Hostname</router-link>
                            </p>
                            </template>

                            <!-- <template v-if="isPermitServiceAdmin">
                            <p class="text-overline mb-0 mt-10">Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="green--text"></font-awesome-icon></p>
                            <p class="mb-0 pb-0">
                            </p>
                            </template> -->

                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
// import UserList from '@/components/organization-dashboard/UserList.vue';
import {
    ORGANIZATION_SUBSCRIPTION_ESSENTIAL,
    ORGANIZATION_SUBSCRIPTION_BUSINESS,
    ORGANIZATION_SUBSCRIPTION_ENTERPRISE,
} from '@/sdk/constants';

export default {
    components: {
        // UserList,
    },
    data: () => ({
        organization: null,
        organizationSummary: null,
        error: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
            organization: (state) => state.organization,
            focus: (state) => state.focus,
        }),
        organizationName() {
            return this.organization?.name ?? 'Unknown';
        },
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        organizationSubscriptionDisplay() {
            let displayText;
            switch (this.organization?.subscription) {
            case ORGANIZATION_SUBSCRIPTION_ESSENTIAL:
                displayText = 'Essential';
                break;
            case ORGANIZATION_SUBSCRIPTION_BUSINESS:
                displayText = 'Business';
                break;
            case ORGANIZATION_SUBSCRIPTION_ENTERPRISE:
                displayText = 'Enterprise';
                break;
            default:
                displayText = this.organization?.subscription;
                break;
            }
            return displayText;
        },
    },
    watch: {
        focus() {
            this.loadOrganizationSummary();
        },
    },
    methods: {
        async loadOrganization() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadOrganization: true });
                const response = await this.$client.organization(this.$route.params.organizationId).currentOrganization.get();
                console.log(`organization/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.organization = response;
                } else {
                    // TODO: redirect back to organization list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load organization', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadOrganization: false });
            }
        },
        async loadOrganizationSummary() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadOrganizationSummary: true });
                const response = await this.$client.organization(this.$route.params.organizationId).currentOrganization.check({ item: 'summary' });
                console.log(`organization/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.organizationSummary = response; // {"domain_count":2,"domain_verification_count":0,"dynamic_dns_count":0,"user_count":1,"domain_dispute_defendant":0,"domain_dispute_claimant":0,"dns_record_count":4}
                } else {
                    // TODO: redirect back to organization list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load organization report', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadOrganizationSummary: false });
            }
        },
    },
    mounted() {
        this.loadOrganization();
        this.loadOrganizationSummary();
    },
};
</script>
